@import "variables";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "custom";
@import "ie-fix";

.column {
  min-width: 100px;
  line-height: 24px;
  cursor: pointer;
  p {
    white-space: nowrap;
  }
  span {
    font-size: 24px;
  }
}

.well {
  background: #efefef;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  position: relative;
  &-data {
    height: 360px;
    overflow-y: scroll;
  }
  &-table {
    height: 200px;
    overflow-y: scroll;
    table tr td {
      padding: 5px;
    }
  }
  button {
    width: 20px;
    height: 20px;
    background: #cc3535;
    font-size: 14px;
    font-weight: 800;
    color: #fff;
    border: none;
    border-radius: 5px;
    line-height: 19px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  h6 {
    margin-bottom: 15px;
  }
}

.react-datepicker-wrapper {
  display: block;
  margin-bottom: 10px;
}

.datepicker {
  width: 100%;
}